import {Controller} from 'stimulus';
import {emailValidation} from '../services/authentications/email_service';
import {validateLength} from '../services/authentications/min-length_service';
import {freeFieldValidation} from '../services/authentications/free_field_service';
import {streetNameValidation} from '../services/authentications/street-validation_service';
import {zipcodeValidation} from '../services/authentications/zipcode_service';
import {AsYouType} from 'libphonenumber-js';
import {phoneValidation} from '../services/authentications/phone_service';
import {validityCount} from '../services/authentications/validation-count_service';
import {completeValidation} from '../services/authentications/complete-validation_service';
import {countryCode} from '../services/authentications/country-code_service';
import {setMessage} from '../services/authentications/base_service';
import {I18n} from '../translation';
import flatpickr from 'flatpickr';
import {passwordValidation} from '../services/authentications/password_service';
import {message} from '../services/shared';
import {cartPriceAndItemReplaceDom} from '../services/carts/replace_dom';


export default class extends Controller {
  selectedOption = undefined;
  bothCacAndWarehouseStock = false;
  onlyCacStock = false;
  onlyWarehouseStock = false;
  cart = undefined;
  lineItemsInfo = [];

  static targets = ['option', 'shippingDetails', 'guestInformation', 'addNewAddress'];

  validity = {
    email: {func: (dom, errorId, showError) => emailValidation(dom, errorId, showError), value: false},
    first_name: {func: (dom, errorId, showError) => freeFieldValidation(dom, errorId, showError), value: false},
    last_name: {func: (dom, errorId, showError) => freeFieldValidation(dom, errorId, showError), value: false},
    street_name: {func: (dom, errorId, showError) => streetNameValidation(dom, errorId, showError), value: false},
    city: {func: (dom, errorId, showError) => freeFieldValidation(dom, errorId, showError), value: false},
    zipcode: {func: (dom, errorId, showError) => zipcodeValidation(dom, errorId, showError), value: false},
    agree_terms: {value: false},
    valid_coupons: {value: true}
  };
  additionalValidity = {
    first_name: {func: (dom, errorId, showError) => freeFieldValidation(dom, errorId, showError), value: false},
    last_name: {func: (dom, errorId, showError) => freeFieldValidation(dom, errorId, showError), value: false},
    street_name: {func: (dom, errorId, showError) => streetNameValidation(dom, errorId, showError), value: false},
    city: {func: (dom, errorId, showError) => freeFieldValidation(dom, errorId, showError), value: false},
    zipcode: {func: (dom, errorId, showError) => zipcodeValidation(dom, errorId, showError), value: false},
  };
  billingValidity = {
    first_name: {func: (dom, errorId, showError) => freeFieldValidation(dom, errorId, showError), value: false},
    last_name: {func: (dom, errorId, showError) => freeFieldValidation(dom, errorId, showError), value: false},
    street_name: {func: (dom, errorId, showError) => streetNameValidation(dom, errorId, showError), value: false},
    city: {func: (dom, errorId, showError) => freeFieldValidation(dom, errorId, showError), value: false},
    zipcode: {func: (dom, errorId, showError) => zipcodeValidation(dom, errorId, showError), value: false},
  };
  authenticatedValidity = {
    email: false,
    shipping_address: false,
    agree_terms: false
  };
  isAuthenticated = JSON.parse(this.element.dataset.authenticated);
  code = countryCode('Switzerland');
  validCount = ['guest_shipping_phone'];
  phoneRequiredCount = 1;
  additionalAddress = false;
  differentBilling = false;
  registration = false;
  isMembershipOnly = false;
  hasOnlyEGiftCardsAdded = false;

  connect() {
    if (this.isAuthenticated) {
      this.authenticatedValidity.email = true;
      let radio = document.getElementById('user_default_address');
      if (radio) {
        const radioId = radio.dataset.id;
        let formDiv = document.getElementById(`div-address-form-${radioId}`);
        if (formDiv) {
          this.authenticatedValidity.shipping_address = true;
        }
      }
      if (JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))) {
        this.authenticatedValidity['membership_terms'] = false;
      }
    }
    if (JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))) {
      this.addGuestMemberValidations();
    }
    this.switchContinueButton();
    this.dateTimeAddress();
    this.dateTimeUser();
    this.removeEmailInput();
    this.setDefaultDeliveryOption('clickAndCollect');
  }

  setDefaultDeliveryOption(optionName) {
    const option = this.getOptionTarget(optionName);
    const inStoreAdminOption = this.getOptionTarget('inStore');
    if (option) {
      option.classList.add('text-blue', 'border-blue');
      this.hideShippingInfoSection(optionName);
      this.selectedOption = optionName;
      this.isAuthenticated ? this.authenticatedCheckoutButton() : this.checkButton();
    }
    if(['admin'].includes(inStoreAdminOption?.dataset?.currentRole)) {
      inStoreAdminOption.classList.add('text-blue', 'border-blue');
      this.selectedOption = 'inStore';
      this.invoicePayment(undefined, true);
    }

    const event = {
      currentTarget: {
        dataset: {option: this.selectedOption}
      }
    };
    this.toggleDeliveryOption(event);
  }

  removeEmailInput() {
    if (document.getElementById('guest_checkout_email')?.value === '') {
      document.getElementById('add-registration-guest').classList.add('hidden');
    }
  }

  reValidate() {
    let guestMemberValidityFields = [
      'checkout_email',
      'guest_shipping_first_name',
      'guest_shipping_last_name',
      'guest_shipping_street_name',
      'guest_address_shipping_zipcode',
      'guest_shipping_city'
    ];
    let validityFields = [
      'guest_checkout_email',
      'guest-shipping_first_name',
      'guest-shipping_last_name',
      'guest-shipping_street_name',
      'guest-shipping_zipcode',
      'guest-shipping_city'
    ];
    let guestMembershipValidityFields = [
      'guest_shipping_gender',
      'address-dob'
    ];
    let userMembershipValidityFields = [
      'user_gender',
      'user-dob'
    ];
    if (this.isAuthenticated) {
      this.authenticatedValidity.email = true;
      let radio = document.getElementById('user_default_address');
      if (radio) {
        const radioId = radio.dataset.id;
        let formDiv = document.getElementById(`div-address-form-${radioId}`);
        if (formDiv) {
          this.authenticatedValidity.shipping_address = true;
        }
      }
      this.authenticatedValidity['agree_terms'] = document.getElementById('agree_terms').checked;
      if (JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))) {
        this.authenticatedValidity['membership_terms'] = document.getElementById('membership_terms').checked;
        userMembershipValidityFields.forEach(field => {
          this.validateAuthenticatedPresence(document.getElementById(field), false);
        });
      } else {
        this.authenticatedValidity['membership_terms'] = true;
      }
      this.authenticatedCheckoutButton();
    } else {
      this.validity['agree_terms'] = {value: document.getElementById('agree_terms').checked};
      if (JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))) {
        this.validity['membership_terms'] = {value: document.getElementById('membership_terms').checked};
        guestMemberValidityFields.forEach(field => {
          this.fieldValidation(document.getElementById(field), false);
        });
        guestMembershipValidityFields.forEach(field => {
          this.validateAuthenticatedPresence(document.getElementById(field), false);
        });
        this.validatePassword();
      } else {
        validityFields.forEach(field => {
          this.fieldValidation(document.getElementById(field), false);
        });
        this.validity['password'] = {value: true};
        this.validity['dob'] = {value: true};
        this.validity['gender'] = {value: true};
        this.validity['membership_terms'] = {value: true};
      }
      this.checkButton();
    }
  }

  dateTimeAddress() {
    flatpickr('#address-dob', {
      dateFormat: 'd.m.Y',
      maxDate: new Date().fp_incr(0),
      disableMobile: true
    });
  }

  dateTimeUser() {
    flatpickr('#user-dob', {
      dateFormat: 'd.m.Y',
      maxDate: new Date().fp_incr(0),
      disableMobile: true
    });
  }

  switchContinueButton() {
    let dom = document.getElementById('guest');
    if (dom) {
      if (JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))) {
        dom.innerText = I18n[window.currentLocale]['its_my_first_time'];
      } else {
        dom.innerText = I18n[window.currentLocale]['continue_as_guest'];
      }
      // TODO: when coming to cart page with ?member_check=true, even if the toggle is on, the session is not set(debug this)
      if (document.getElementById('purchase_membership_toggle')?.checked) {
        if (this.isAuthenticated) {
          dom?.classList?.remove('hidden');
        } else {
          dom?.classList?.add('hidden');
        }
      }
    }
  }

  validateAuthenticatedPresence(event, showError = true) {
    if (!event) {
      return;
    }
    let dom = event?.currentTarget || event;
    this.authenticatedValidity[dom.name] = !!dom.value.trim();
    if (this.validity[dom.name]) {
      this.validity[dom.name] = {
        func: (dom, errorId, showError) => freeFieldValidation(dom, errorId, showError),
        value: !!dom.value.trim()
      };
    }
    if (showError) {
      setMessage(
        `${dom.id}_error_message`,
        !dom.value.trim() ? I18n[window.currentLocale]['field_required'] : ''
      );
    }
    this.authenticatedCheckoutButton();
  }

  addGuestMemberValidations() {
    this.validity['membership_terms'] = {value: false};
    this.validity['password'] = {value: false};
    this.validity['dob'] = {
      func: (dom, errorId, showError) => freeFieldValidation(dom, errorId, showError),
      value: false
    };
    this.validity['gender'] = {
      func: (dom, errorId, showError) => freeFieldValidation(dom, errorId, showError),
      value: false
    };
    const element = document.getElementById('purchase_membership_toggle');
    if (element) {
      this.hasMembershipChecked = JSON.parse(sessionStorage.getItem('purchaseMembershipOn'));
      this.toggleShippingAddressContainerVisibility(element);
    }
  }

  removeMembershipValidation(event) {
    if (['continue'].includes(event.currentTarget.id)){
      this.checkForEGiftCard(event);
      return;
    }
    if (!this.isAuthenticated) {
      if (event.currentTarget.checked) {
        this.addGuestMemberValidations();
      } else {
        this.validity['membership_terms'] = {value: true};
        this.validity['password'] = {value: true};
        this.validity['dob'] = {value: true};
        this.validity['gender'] = {value: true};
      }
      this.toggleShippingAddressContainerVisibility(event);
      this.checkButton();
    } else {
      this.isMembershipOnly = !JSON.parse(event.currentTarget?.dataset?.cart) && this.shippingDetailsTarget && event?.currentTarget?.checked;
      this.hasMembershipChecked = event?.currentTarget?.checked;
      this.toggleShippingAddressContainerVisibility(event);
      this.authenticatedValidity['membership_terms'] = !event.currentTarget.checked;
      this.authenticatedCheckoutButton();
    }
    this.checkForEGiftCard(event);
  }

  isOnlyMemberShip(event) {
    return event.currentTarget?.dataset?.cart && JSON.parse(sessionStorage.getItem('purchaseMembershipOn'));
  }

  async checkForEGiftCard(event) {
    const [hasCart, cardId] = [event.currentTarget?.dataset?.cart, event.currentTarget?.dataset?.cartId];
    if (JSON.parse(hasCart) && cardId) {
      await fetch(`/carts/${event.currentTarget?.dataset?.cartId}/check_e_gift_card`, {
        method: 'GET',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Content-Type': 'application/json'
        },
      }).then((response) => response.text()).then((data) => {
        this.hasOnlyEGiftCardsAdded = JSON.parse(data)['only_e_gift_cards'];
        this.hideShippingDetailContainer = JSON.parse(data)['remove_shipping_address_container'];
        this.checkButton();
        this.authenticatedCheckoutButton();
      });
      this.toggleShippingAddressContainerVisibility(event);
    }
    else {
      this.toggleShippingAddressContainerVisibility(event);
    }
  }

  toggleShippingAddressContainerVisibility(event) {
    if (this.selectedOption && this.hasShippingDetailsTarget) {
      this.hideShippingInfoSection(this.selectedOption);
      return;
    }
    const dataSet = event['currentTarget']?.dataset || event['target']?.dataset || event?.dataset ;
    this.hasMembershipChecked = event?.currentTarget?.checked || event?.target?.checked || event?.checked || JSON.parse(sessionStorage.getItem('purchaseMembershipOn'));
    this.isMembershipOnly = !JSON.parse(dataSet?.cart) && this.hasMembershipChecked;
    if (!JSON.parse(dataSet?.cart) && this.hasShippingDetailsTarget) {
      this.shippingDetailsTarget.style.display = this.hasMembershipChecked ? 'none' : 'block';
      this.hasAddNewAddressTarget && (this.addNewAddressTarget.style.display = this.isMembershipOnly ? 'none' : 'block');
    }
    else {
      this.hasShippingDetailsTarget && (this.shippingDetailsTarget.style.display = this.hasOnlyEGiftCardsAdded || (this.hasOnlyEGiftCardsAdded && this.hasMembershipChecked) ? 'none' : 'block');
    }
  }

  displayAddressDynamically(event) {
    this.registration = JSON.parse(event?.currentTarget.dataset?.registration || '');
    if (!this.isAuthenticated) {
      if (JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))) {
        document.getElementById('normal-address').classList.add('hidden');
        document.getElementById('membership-checked').classList.remove('hidden');
      } else {
        document.getElementById('membership-checked').classList.add('hidden');
        document.getElementById('normal-address').classList.remove('hidden');
      }
    } else {
      document.getElementById('normal-address').classList.remove('hidden');
      document.getElementById('membership-checked').classList.add('hidden');
      if (JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))) {
        document.getElementById('membership-additional-field')?.classList?.remove('hidden');
      } else {
        document.getElementById('membership-additional-field')?.classList?.add('hidden');
      }
    }
    this.reValidate();
  }

  togglePasswordVisibility(event) {
    let element = document.getElementById(event.currentTarget.dataset.targetId);
    let typeField = element.type;
    element.type = typeField === 'text' ? 'password' : 'text';
    event.currentTarget.innerHTML = typeField === 'text' ? 'visibility_off' : 'visibility';
  }

  setGender(event) {
    let error_id = '';
    if (this.isAuthenticated) {
      this.authenticatedCheckoutButton();
    } else {
      document.getElementById('guest_shipping_actual_gender').value = event.currentTarget.dataset.gender;
      document.getElementById('guest_shipping_gender').value = event.currentTarget.dataset.trGender;
      error_id = 'guest_shipping_gender';
      this.validity['gender'] = {value: true};
      this.checkButton();
    }
    if (document.getElementById(`${error_id}_error_message`)) {
      setMessage(
        `${error_id}_error_message`,
        !document.getElementById(error_id).value.trim() ? I18n[window.currentLocale]['field_required'] : ''
      );
    }
  }

  fieldValidation(event, showError = true) {
    this.validateLength(event);
    const dom = event?.currentTarget || event;
    if (this.additionalAddress && dom.id.includes('guest_additional')) {
      const type = Object.keys(this.additionalValidity).find((key) => dom.id.includes(key));
      this.additionalValidity[type].value = this.additionalValidity[type].func(dom, `${dom.id}_error_message`, showError);
      this.checkAdditional();
    } else if (this.differentBilling && dom.id.includes('guest_billing')) {
      const type = Object.keys(this.billingValidity).find((key) => dom.id.includes(key));
      this.billingValidity[type].value = this.billingValidity[type].func(dom, `${dom.id}_error_message`, showError);
      this.checkBilling();
    } else {
      if (this.isAuthenticated) {
        let radio = document.getElementById('user_default_address');
        if (radio) {
          const radioId = radio.dataset.id || dom.id;
          let formDiv = document.getElementById(`div-address-form-${radioId}`);
          if (formDiv) {
            this.authenticatedValidity.shipping_address = true;
          }
        }
        this.authenticatedCheckoutButton();
      } else {
        const type = Object.keys(this.validity || {}).find((key) => dom && dom?.id?.includes(key));
        if (this.validity[type]) {
          this.validity[type].value = this.validity[type].func(dom, `${dom.id}_error_message`, showError);
        }
        this.checkButton();
      }
    }
    this.checkButton();
  }

  checkTerms(event) {
    if (this.isAuthenticated) {
      this.authenticatedValidity.agree_terms = event.currentTarget.checked;
      this.authenticatedCheckoutButton();
    } else {
      this.validity.agree_terms = {value: event.currentTarget.checked};
      this.checkButton();
    }
  }

  showBillingAddress(event) {
    this.differentBilling = event.currentTarget.checked;
    if (event.currentTarget.checked) {
      this.checkBilling();
      document.getElementById('billing-address').removeAttribute('hidden');
      if (!this.validCount.includes('guest_billing_phone')) {
        this.validCount.push('guest_billing_phone');
      }
      this.phoneRequiredCount += 1;
    } else {
      document.getElementById('billing-address').setAttribute('hidden', true);
      this.validity['billing'] = {value: true};
      this.checkButton();
      if (this.validCount.includes('guest_billing_phone')) {
        this.validCount.splice(this.validCount.indexOf('guest_billing_phone'), 1);
      }
      this.phoneRequiredCount -= 1;
    }
  }

  validatePassword() {
    let valid = false;
    let validPassword = false;
    let password;
    let confirmPassword;
    if (window.location.search.includes('register=true') && !(JSON.parse(sessionStorage.getItem('purchaseMembershipOn')))) {
      password = document.getElementById('guestPassword');
      confirmPassword = document.getElementById('passwordConfirm');
    } else {
      password = document.getElementById('password');
      confirmPassword = document.getElementById('confirm-password');
    }
    if (password) {
      validPassword = passwordValidation(password, `${password.id}_error_message`);
    }
    if (password?.value && confirmPassword?.value) {
      valid = password.value === confirmPassword.value;
    }
    this.validity['password'] = {value: valid && validPassword};
    if (password?.value && confirmPassword?.value) {
      if (valid) {
        setMessage(`${confirmPassword.id}_error_message`, '');
      } else {
        setMessage(`${confirmPassword.id}_error_message`, I18n[window.currentLocale]['password_should_match']);
      }
    }
    this.checkButton();
  }

  checkMembershipTerms(event) {
    if (this.isAuthenticated) {
      if (JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))) {
        this.authenticatedValidity['membership_terms'] = event.currentTarget.checked;
      }
      this.authenticatedCheckoutButton();
    } else {
      if (JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))) {
        this.validity.membership_terms = {value: event.currentTarget.checked};
      }
      this.checkButton();
    }
  }

  selectPaymentMethod(event) {
    this.validity.payment_method = event.currentTarget.checked;
    this.checkButton();
  }

  ValidatePhone(event) {
    this.validateLength(event);
    const input = event.currentTarget;
    input.value = new AsYouType(this.code['short_name']).input(input.value);
    let phoneStatus = phoneValidation(input, this.code['short_name']);
    validityCount(phoneStatus, input.id, this.validCount);
    completeValidation('checkout_button', this.validCount.length !== this.phoneRequiredCount);
    if (this.validCount.length === this.phoneRequiredCount) {
      if (this.isAuthenticated) {
        this.authenticatedCheckoutButton();
      } else {
        this.checkButton();
      }
    }
  }

  validateLength(e) {
    let lengthValid;
    if (e?.currentTarget) {
      lengthValid = validateLength(e?.currentTarget);
    } else {
      lengthValid = validateLength(e);
    }
    if (!lengthValid) e.stopImmediatePropagation();
  }

  additionAddress(event) {
    if (event.currentTarget.checked) {
      document.getElementById('additional-address').classList.remove('hidden');
      this.checkAdditional();
      if (!this.validCount.includes('guest_additional_phone')) {
        this.validCount.push('guest_additional_phone');
      }
      this.phoneRequiredCount += 1;
    } else {
      document.getElementById('additional-address').classList.add('hidden');
      this.validity['additional'] = {value: true};
      this.checkButton();
      if (this.validCount.includes('guest_additional_phone')) {
        this.validCount.splice(this.validCount.indexOf('guest_additional_phone'), 1);
      }
      this.phoneRequiredCount -= 1;
    }
    this.additionalAddress = event.currentTarget.checked;
  }

  checkButton() {
    document.getElementById('checkout_button').disabled = this.lineItemsInfo.every(info => info.hasValidStockQuantity) ? this.shouldDisablePaymentButton() : true;
  }
  
  shouldDisablePaymentButton() {
    const { eGiftCardValidation, hasBothMembershipAndGiftCardAdded, memberTerms} = this.userValidations();

    if (this.hasShippingDetailsTarget && ['clickAndCollect'].includes(this.selectedOption)) {
      return !(this.isAuthenticated ? this.authenticatedValidity.email && this.authenticatedValidity.agree_terms : this.validity.agree_terms.value && this.validity.email.value);
    }

    if (hasBothMembershipAndGiftCardAdded || this.isMembershipOnly) {
      return !memberTerms;
    }

    if (this.hasOnlyEGiftCardsAdded) {
      return !eGiftCardValidation;
    }

    return !this.hasValidFormSelections();
  }

  hasValidFormSelections() {
    const { guestValidation, memberValidation, adminValidation} = this.userValidations();
    return ['inStore'].includes(this.selectedOption) ? adminValidation : (this.isAuthenticated ? memberValidation : guestValidation);
  }

  userValidations() {
    const [guestValidation, memberValidation] = [Object.keys(this.validity).every(key => this.validity[key].value), Object.keys(this.authenticatedValidity).every(key => this.authenticatedValidity[key])];
    const guestEmail = document.getElementById('guest_email')?.value;
    return {
      guestValidation,
      memberValidation,
      adminValidation: this.authenticatedValidity.agree_terms && !!guestEmail,
      eGiftCardValidation: this.isAuthenticated ? (this.authenticatedValidity.agree_terms && this.authenticatedValidity.email) : (this.validity.agree_terms.value && this.validity.email.value),
      hasBothMembershipAndGiftCardAdded: this.hasOnlyEGiftCardsAdded && this.hasMembershipChecked,
      memberTerms: this.authenticatedValidity['agree_terms'] && this.authenticatedValidity['membership_terms'],
      guestTerms: this.validity['agree_terms']
    };
  }

  checkAdditional() {
    let keys = Object.keys(this.additionalValidity);
    let valid = false;
    for (let key of keys) {
      valid = this.additionalValidity[key].value;
      if (!valid) {
        break;
      }
    }
    this.validity['additional'] = {value: valid};
    this.checkButton();
  }

  checkBilling() {
    let keys = Object.keys(this.billingValidity);
    let valid = false;
    for (let key of keys) {
      valid = this.billingValidity[key].value;
      if (!valid) {
        break;
      }
    }
    this.validity['billing'] = {value: valid};
    this.checkButton();
  }

  authenticatedCheckoutButton() {
    document.getElementById('checkout_button').disabled = this.lineItemsInfo.every(info => info.hasValidStockQuantity) ? this.shouldDisablePaymentButton() : true;
  }

  setGenderShipping(event) {
    document.getElementById('add_gender_shipping').value = event.currentTarget.dataset.select;
    document.getElementById('hidden_actual_gender').value = event.currentTarget.dataset.value;
  }

  checkEmailExist(event) {
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const valid = emailPattern.test(event.currentTarget.value.toLowerCase());
    if (valid) {
      fetch('/users/check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({email: event.currentTarget.value.toLowerCase()})
      }).then(response => {
        if (response.ok) {
          response.json().then(value => {
            if (value['present']) {
              document.getElementById('checkout_email_error_message').innerHTML = `<p> ${I18n[window.currentLocale]['email_exist']}. <a style="text-decoration: underline" data-controller="login"  data-action="click->login#login">${I18n[window.currentLocale]['please_login']}</a></p>`;
              document.getElementById('add-registration').classList.add('hidden');
              if (window.location.search.includes('register=true') && this.registration && !(JSON.parse(sessionStorage.getItem('purchaseMembershipOn')))) {
                document.getElementById('guest_checkout_email_error_message').innerHTML = `<p> ${I18n[window.currentLocale]['email_exist']}. <a style="text-decoration: underline" data-controller="login" data-action="click->login#login">${I18n[window.currentLocale]['please_login']}</a></p>`;
                document.getElementById('add-registration-guest').classList.add('hidden');
              }
            } else {
              document.getElementById('add-registration').classList.remove('hidden');
              document.getElementById('checkout_email_error_message').innerText = '';
              if (window.location.search.includes('register=true') && this.registration && !(JSON.parse(sessionStorage.getItem('purchaseMembershipOn')))) {
                document.getElementById('add-registration-guest').classList.remove('hidden');
                document.getElementById('guest_checkout_email_error_message').innerText = '';
              }
            }

            if (value['valid_coupon']) {
              this.validity.valid_coupons.value = true
              document.getElementById('guest_checkout_coupon_error_message').innerText = '';
            } else {
              this.validity.valid_coupons.value = false
              document.getElementById('guest_checkout_coupon_error_message').innerText = I18n[window.currentLocale]['coupon_invalid'];
            }
            this.checkButton();
          });
        } else { /* empty */
        }
      });
    }
  }

  invoicePayment(event, setDefault = false) {
    if (event?.currentTarget?.checked || setDefault) {
      document.getElementById('checkout_button').innerText = I18n[window.currentLocale]['place_order'];
    } else {
      document.getElementById('checkout_button').innerText = I18n[window.currentLocale]['proceed_to_payment'];
    }
  }

  getCart() {

  }

  async toggleDeliveryOption(event) {
    const selectedOption = event?.currentTarget?.dataset?.option;
    this.selectedOption = selectedOption;
    this.optionTargets.forEach(option => {
      option.classList.remove('text-blue', 'border-blue');
    });

    if (this.selectedOption) {
      const selectedOptionElement = this.optionTargets.find(option => option.dataset.option === selectedOption);
      if (selectedOptionElement) {
        selectedOptionElement.classList.add('text-blue', 'border-blue');
      }
      this.hideShippingInfoSection(selectedOption);
    }
    this.updateEmailValue();
    this.checkButton();

    const response = await this.getStockMetaData();
    const data = await response.json();
    if (!data.error) {
      cartPriceAndItemReplaceDom(data);
    } else {
      message(data.message, 'error');
    }
    this.disableDeliveryOptions(data);
  }

  getStockMetaData() {
    return fetch('/items/placeholder_id', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        item: {
          shippingOption: this.selectedOption,
          membership: JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))
        }
      })
    });
  }

  disableDeliveryOptions(data) {
    this.bothCacAndWarehouseStock = data['both_cac_and_warehouse_stock'];
    this.onlyCacStock = data['only_cac_stock'];
    this.onlyWarehouseStock = data['only_warehouse_stock'];
    this.cart = data['cart'];
    this.setSelectedDeliveryOption(data);
    data['fan_app_session'] && this.setItemsErrorMessages(data);
    if (this.selectedOption) {
      this.setItemsErrorMessages(data);
      const { target, complementaryTarget } = this.findTargets();
      this.toggleStyles(target || this.optionTargets[0], complementaryTarget);
      this.hideShippingInfoSection(this.selectedOption);
    }
    this.reCalculateShippingPrices();
  }

  setSelectedDeliveryOption(data) {
    const currentRole = this.optionTargets[0]?.dataset?.currentRole;

    if (!data['fan_app_session']) {
      this.selectedOption = ['admin'].includes(currentRole) ? 'inStore' : undefined;
    } else {
      if (this.onlyCacStock) {
        this.selectedOption = 'clickAndCollect';
      } else if (this.onlyWarehouseStock) {
        this.selectedOption = 'shipping';
      } else if (this.bothCacAndWarehouseStock && !this.selectedOption) {
        this.selectedOption = 'clickAndCollect';
      }
    }
  }

  async reCalculateShippingPrices() {
    const response = await this.getStockMetaData();
    const data = await response.json();
    if (!data.error) {
      cartPriceAndItemReplaceDom(data);
    } else {
      message(data.message, 'error');
    }
    data['fan_app_session'] && this.setItemsErrorMessages(data);
  }

  findTargets() {
    let target, complementaryTarget;
    const findOption = (optionType) => this.optionTargets.find(option => option.dataset?.option === optionType);

    if (this.bothCacAndWarehouseStock) {
      target = findOption(this.selectedOption);
    } else {
      target = findOption(this.onlyCacStock ? 'clickAndCollect' : 'shipping');
      complementaryTarget = findOption(this.onlyCacStock ? 'shipping' : 'clickAndCollect');
    }
    return {
      target,
      complementaryTarget
    };
  }

  toggleStyles(target, complementaryTarget) {
    this.optionTargets.forEach(option => {
      option.classList.remove('text-blue', 'border-blue');
    });
    if (!this.bothCacAndWarehouseStock && complementaryTarget) {
      complementaryTarget.classList.add('text-black/[0.5]', 'pointer-events-none');
      complementaryTarget.classList.remove('text-blue', 'border-blue');
    }
    if (target) {
      target.classList.add('text-blue', 'border-blue');
    }
  }

  setItemsErrorMessages(data) {
    data['line_items_info'].forEach(item => {
      const element = document.getElementById(item.id.toString() + '_error_message');
      if (element) {
        item['hasValidStockQuantity'] = this.checkStockValidity(item);
        !item['hasValidStockQuantity'] && (element.innerHTML = data.error_message);
      }
    });
    this.lineItemsInfo = data['line_items_info'];
    const hasValidStockSelection = this.lineItemsInfo.every(info => info.hasValidStockQuantity);
    if (!hasValidStockSelection) {
      const element = document.getElementById('mis-match-stock');
      element.innerHTML = data.mismatch_stock_warning;
    }
    else {
      document.getElementById('mis-match-stock').innerHTML = '<span></span>';
    }
    this.isAuthenticated ? this.authenticatedCheckoutButton() : this.checkButton();
  }

  checkStockValidity(item) {
    switch (this.selectedOption) {
    case 'shipping':
      return item.quantity <= item.count_on_hand;
    case 'clickAndCollect': case 'inStore':
      return item.quantity <= item.cac_intermediate_stock;
    default:
      return false;
    }
  }


  updateEmailValue() {
    const [cacEmailInput, shippingEmailInput] = [document.getElementById('guest_shipping_checkout_email'), document.getElementById('guest_optional_checkout_email')];
    const [cacEmail, shippingEmail] = [cacEmailInput?.value, shippingEmailInput?.value];
    cacEmailInput && (cacEmailInput.value = cacEmail || shippingEmail);
    shippingEmailInput && (shippingEmailInput.value = shippingEmail || cacEmail);
    !this.validity.email.value && (this.validity.email.value = [cacEmail, shippingEmail].some(value => !!value));
  }

  /*  hideShippingInfoSection(selectedOption) {
    if (selectedOption) {
      const [shippingDetailsTarget, guestInformationTarget] = [this.shippingDetailsTargets?.[0], this.guestInformationTargets[0]];
      if (this.hideShippingDetailContainer) {
        shippingDetailsTarget && (shippingDetailsTarget.style.display = 'none');
        this.addNewAddressTarget.style.display = this.isMembershipOnly ? 'none' : 'block';
      }
      else {
        shippingDetailsTarget && (shippingDetailsTarget.style.display = selectedOption === 'shipping' ? 'block' : 'none');
        guestInformationTarget && (guestInformationTarget.style.display = selectedOption === 'inStore' ? 'block' : 'none');
        this.addNewAddressTarget.style.display = selectedOption === 'shipping' ? 'block' : 'none';
        if (document.getElementById('guest-contact-info')){
          document.getElementById('guest-contact-info').style.display = selectedOption === 'clickAndCollect' ? 'flex' : 'none';
        }
      }
    }
  }*/

  hideShippingInfoSection(selectedOption) {
    if (!selectedOption) return;

    const shippingDetailsTarget = this.shippingDetailsTargets?.[0];
    const guestInformationTarget = this.guestInformationTargets[0];
    const guestContactInfo = document.getElementById('guest-contact-info');
    const guestBillingAddress = document.getElementById('guest_billing_address');

    const setDisplay = (element, displayStyle) => {
      if (element) element.style.display = displayStyle;
    };

    if (this.hideShippingDetailContainer || this.isMembershipOnly) {
      setDisplay(shippingDetailsTarget, 'none');
      this.hasAddNewAddressTarget && setDisplay(this.addNewAddressTarget, 'none');
      return;
    }

    if (shippingDetailsTarget) {
      const shouldShowShippingDetails = !this.isMembershipOnly && selectedOption === 'shipping';
      setDisplay(shippingDetailsTarget, shouldShowShippingDetails ? 'block' : 'none');
      this.hasAddNewAddressTarget && setDisplay(this.addNewAddressTarget, selectedOption === 'shipping' ? 'block' : 'none');
    }

    setDisplay(guestInformationTarget, selectedOption === 'inStore' ? 'block' : 'none');

    setDisplay(guestContactInfo, selectedOption === 'clickAndCollect' ? 'flex' : 'none');

    setDisplay(guestBillingAddress, selectedOption === 'clickAndCollect' ? 'none' : 'block');
  }


  getOptionTarget(optionName) {
    return this.optionTargets.find((option) => option?.dataset?.option === optionName);
  }
}
