import {Controller} from 'stimulus';

export default class extends Controller {
  selectedRecommendation = [];
  selectedRecommendationDom = document.getElementById('selected-product-recommendations');
  productRecommendationsCount = document.getElementById('recommendations_count');

  connect() {
    if(window.location.pathname.includes('edit') && this.element.id !== '') {
      if (this.element.dataset?.recommendations) {
        this.selectedRecommendation = JSON.parse(this.element.dataset?.recommendations);
      }
      document.getElementById('product-recommendations-field').value = this.selectedRecommendation;
    }
  }

  search(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.searchProduct(event), 500);
  }

  searchProduct(event) {
    let url = `/admin/product-recommendations?q=${event.currentTarget.value}`;

    fetch(url, {}).then(response => {
      response.text().then(text => {
        let tmpDom = document.createElement('div');
        tmpDom.innerHTML = text;

        document.getElementById('recommendations-search-results').replaceWith(tmpDom.querySelector('#recommendations-search-results'));
      });
    });
  }

  selectRecommendation(event) {
    let alreadyPresent = this.saveSelection(event);

    !alreadyPresent ? this.appendToSelectedRecommendationDom(event.currentTarget) : null;
  }

  appendToSelectedRecommendationDom(dom) {
    let customizedDom = dom.cloneNode(true);
    customizedDom.querySelector('#close-btn-recommendation').innerHTML = 'close';
    this.selectedRecommendationDom.appendChild(customizedDom);
  }

  removeSelection(event) {
    let targetDom = event.currentTarget.parentElement;
    this.selectedRecommendation = this.selectedRecommendation.filter(id => id !== targetDom.dataset.productId);
    this.productRecommendationsCount.innerHTML = this.selectedRecommendation.length;

    // remove from stepper dom
    event.currentTarget.parentElement.remove();

    // remove from recommendation dom
    let recommendationModalDom = document.getElementById('selected-product-recommendations');
    recommendationModalDom?.querySelector(`[data-product-id="${event.currentTarget.dataset.scopeId}"]`)?.remove();

    document.getElementById('product-recommendations-field').value = this.selectedRecommendation;
  }

  cancelRecommendationSelection() {
    this.selectedRecommendationDom.innerHTML = '';
    document.getElementById('modalProductRecommendation').querySelector('#search-fld').value = '';
  }

  saveSelection(event) {
    if (!this.selectedRecommendation.includes(event.currentTarget.dataset.productId)) {
      this.selectedRecommendation.push(event.currentTarget.dataset.productId);
      this.productRecommendationsCount.innerHTML = this.selectedRecommendation.length;
      return false;
    } else {
      return true;
    }
  }

  saveRecommendations(event) {
    let radix = event.currentTarget.dataset.radix;

    if(radix === 'product_configurator_page') {
      fetch(`/admin/chosen-recommendations?product_ids=${this.selectedRecommendation}`, {}).then(response => {
        response.text().then(text => {
          let tmpDom = document.createElement('div');
          tmpDom.innerHTML = text;

          if (text) document.getElementById('saved-recommendations').replaceWith(tmpDom.firstChild);
          document.getElementById('modalProductRecommendation').click();
          document.getElementById('product-recommendations-field').value = this.selectedRecommendation;
        });
      });
    } else {
      let productId = event.currentTarget.dataset.productId;
      this.selectedRecommendation = [...new Set(this.selectedRecommendation)];

      fetch(`/admin/add-new-recommendation/${productId}?recommended_product_ids=${this.selectedRecommendation}`, {
        method: 'PUT',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        }
      }).then(response => {
        response.text().then(text => {
          let tmpDom = document.createElement('div');
          tmpDom.innerHTML = text;

          document.getElementById('product_recommendations').replaceWith(tmpDom);
          document.getElementById('modalProductRecommendation').click();
        });
      });
    }
  }
}
