import {Controller} from 'stimulus';
import {constructArrayParams} from '../services/construct_array_params';
import {PromotionService} from '../services/promotion_service';

export default class extends Controller {
  selectedProduct = [];

  product_check(event) {
    let currentCheckName = event.currentTarget.dataset.productName;
    let currentCheckID = event.currentTarget.dataset.productId;
    if (event.currentTarget.checked) {
      this.addProduct(currentCheckID);
      this.selectedProductList(currentCheckID, 'add', currentCheckName);
    } else {
      this.removeProduct(currentCheckID);
      this.selectedProductList(currentCheckID, 'remove', currentCheckName);
    }
    this.submittable();
  }

  addProduct(product_id) {
    if (!this.selectedProduct.includes(product_id)) {
      this.selectedProduct.push(product_id);
    }
  }

  removeProduct(product_id) {
    if (this.selectedProduct.includes(product_id)) {
      this.selectedProduct.splice(this.selectedProduct.indexOf(product_id), 1);
    }
  }

  submittable() {
    this.selectedProduct.length > 0 ? this.enableSubmitButton() : this.disableSubmitButton();
  }

  enableSubmitButton() {
    document.getElementById('discount_product_submit_button').disabled = false;
  }

  disableSubmitButton() {
    document.getElementById('discount_product_submit_button').disabled = true;
  }

  selectedProductList(product_id, method, product_name) {
    method === 'add' ? this.addProductInSelectedList(product_id, product_name) : this.removeProductFromSelectedList(product_id);
  }

  addProductInSelectedList(product_id, product_name) {
    let vDom = document.createElement('div');
    vDom.className = 'rounded-full bg-gray-100 flex align-start cursor-pointer w-fit hover:bg-blue/[0.08] mb-[12px] items-center px-3 py-2 text-[12px] text-black/[0.87] capitalize';
    vDom.id = `selected_product_${product_id}`;
    let pTag = document.createElement('p');
    pTag.innerHTML = product_name;
    let icon = document.createElement('i');
    icon.className = 'material-icons-outlined !text-[12px] pl-[2px] text-black/[0.6]';
    icon.dataset.action='click->promotion-product-selection#removeSelectedProduct';
    icon.dataset.product_id = product_id;
    icon.innerHTML = 'close';
    vDom.appendChild(pTag);
    vDom.appendChild(icon);
    document.getElementById('selected_discount_product_list_div').appendChild(vDom);
  }

  removeProductFromSelectedList(product_id) {
    document.getElementById(`selected_product_${product_id}`).remove();
  }

  removeSelectedProduct(event) {
    let product_id = event.currentTarget.dataset.product_id;
    if (document.getElementById(`discount_product_checkbox_${product_id}`)) {
      document.getElementById(`discount_product_checkbox_${product_id}`).click();
    } else {
      this.removeProduct(product_id);
      document.getElementById(`selected_product_${product_id}`).remove();
    }
    this.submittable();
  }

  addProductDiscount(event) {
    let url = `/admin/promotions/product_selection?${constructArrayParams(this.selectedProduct, 'selectedProduct')}`;
    fetch(url).then(response => {
      response.text().then(text => {
        document.getElementById('promotion_listing').innerHTML = text;
        // if (event.target.dataset.promotionType === 'coupon')
        //   new PromotionService().updateCouponCountDisplay();
      });
    });
  }

  updatePromotion() {
    document.getElementById('promotion_product_id').value = this.selectedProduct;
    document.getElementById('promotion_product_submit_button').click();
  }
}
