import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';

export default class extends Controller {
  // outOfStockItems

  connect() {
    // this.outOfStockItems = JSON.parse(this.element.dataset.outOfStockItems);
    //
    // if (this.outOfStockItems.length > 0) {
    //   this.outOfStockItems.forEach((item) => {
    //     document.getElementById(`out-of-stock-tag-${item.id}`).classList.remove('hidden');
    //   });
    // }
  }

  shippingAddressFormEvent() {
    // eslint-disable-next-line no-undef
    dataLayer.push({ecommerce: null});
    dataLayer.push(
      {
        event: 'open_shipping_address_form',
        ecommerce: {
          value: 0
        },
      }
    );
  }

  addPaymentInfo(e) {
    let items = JSON.parse(e.currentTarget.dataset.items);
    // eslint-disable-next-line no-undef
    dataLayer.push({ecommerce: null});
    dataLayer.push(
      {
        event: "add_payment_info",
        ecommerce: {
          currency: 'CHF',
          value: +document.getElementById('total_amount').innerText.trim().split(' ')[1],
          items: items
        }
      }
    );
  }

  payment(event) {
    event.preventDefault();
    event.currentTarget.disabled = true;
    const [form, cacShippingForm] = [document.getElementById('address-form'), document.getElementById('address-form-new')];
    const [element1, element3] = [document.getElementById('content-1'), document.getElementById('content-2')];
    const [quantityController1, quantityController3] = [this.application.getControllerForElementAndIdentifier(element1, 'quantity'), this.application.getControllerForElementAndIdentifier(element3, 'checkout')];
    const [variantId, retailerId, membershipId] = [quantityController1.variantId, quantityController1.retailerId, event.currentTarget.dataset.membershipId];
    const [additionalChecked, billingChecked, registrationGuest] = [quantityController3.additionalAddress, quantityController3.differentBilling, quantityController3.registration];
    const [formData, cacShippingFormData] = [new FormData(form), new FormData(cacShippingForm || document.createElement('form'))];
    formData.append('variant_id', variantId);
    formData.append('cac_shippable', ['shipping'].includes(quantityController3.selectedOption) ? 'true' : 'false');
    formData.append('cac_order', ['clickAndCollect', 'inStore'].includes(quantityController3.selectedOption) ? 'true' : 'false');
    formData.append('retailer_id', JSON.stringify(retailerId));
    if (JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))) {
      formData.append('membership_id', membershipId);
    }
    if (window.location.search.includes('register=true') && registrationGuest) {
      formData.append('register_user', 'true');
    } else {
      formData.append('register_user', 'false');
    }
    if (!additionalChecked && JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))) {
      formData.append('phone', formData.get('membership_detail_attributes[phone]'));
      formData.append('city', formData.get('membership_detail_attributes[city]'));
      formData.append('street_name', formData.get('membership_detail_attributes[street_name]'));
      formData.append('zipcode', formData.get('membership_detail_attributes[zipcode]'));
      formData.append('first_name', formData.get('membership_detail_attributes[first_name]'));
      formData.append('last_name', formData.get('membership_detail_attributes[last_name]'));
      formData.append('email', formData.get('membership_detail_attributes[email]').toLowerCase());
      formData.append('gender', formData.get('membership_detail_attributes[gender]'));
      formData.append('membership_detail_attributes[dob]', formData.get('dob'));
    }
    if (billingChecked) {
      let billingForm = document.getElementById('address-form-billing');
      let billingFormData = new FormData(billingForm);
      formData.append('billing_address[phone]', billingFormData.get('billing_address[phone]'));
      formData.append('billing_address[company]', billingFormData.get('billing_address[company]'));
      formData.append('billing_address[country]', billingFormData.get('billing_address[country]'));
      formData.append('billing_address[city]', billingFormData.get('billing_address[city]'));
      formData.append('billing_address[street_name]', billingFormData.get('billing_address[street_name]'));
      formData.append('billing_address[zipcode]', billingFormData.get('billing_address[zipcode]'));
      formData.append('billing_address[first_name]', billingFormData.get('billing_address[first_name]'));
      formData.append('billing_address[last_name]', billingFormData.get('billing_address[last_name]'));
      formData.append('billing_address[state]', billingFormData.get('billing_address[state]'));
    }
    const cleanedFormData = this.removeEmptyFromFormData(formData, cacShippingFormData);

    fetch('/orders', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: cleanedFormData
    }).then(response => {
      if (response.ok) {
        response.json().then(data => {
          window.location.href = data.redirect;
        });
      } else {
        response.json().then(data => {
          popSnackbar('error', data.error);
          window.location.href = data.redirect;
        });
      }
    });
  }

  removeEmptyFromFormData(formData, cacShippingFormData) {
    let newFormData = new FormData();

    for (let [key, value] of formData.entries()) {
      if (value !== null && value !== '' && value !== undefined) {
        newFormData.append(key, value);
      }
    }

    for (let [key, value] of cacShippingFormData.entries()) {
      if (value !== null && value !== '' && value !== undefined) {
        newFormData.append(key, value);
      }
    }

    return newFormData;
  }

  login() {
    window.location.href = `users/sign_in?membership=${JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))}&redirect_to=${window.location.pathname}`;
  }
}
