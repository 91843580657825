import {Controller} from 'stimulus';

export default class extends Controller {
  stepOneNextButton = document.getElementById('step-one-next-btn');
  stepFourNextButton = document.getElementById('step-four-next-btn');
  productActionButton = document.getElementById('product-action-btn');
  variantsSkuBarcodeErrorCounter = 0;
  
  connect() {
    super.connect();
    this.stepOneNextButton.disabled = false;
    this.stepFourNextButton.disabled = false;
    this.productActionButton.disabled = false;
  }

  // For the sake of debugging ease, please do not refactor the following two functions into one
  validateSku(event) {
    clearTimeout(this.timeout);
    const { value: query, dataset: { existingSku, from, index } } = event.currentTarget;
    const [errorElement, variantsErrorElement] = [document.getElementById('product_sku_error_message'), document.getElementById(`variant_${index}_sku_error_message`)];
    if (query && !/^[a-zA-Z0-9]+$/.test(query)) {
      this.setElementError(['variantsTable'].includes(from) ? variantsErrorElement : errorElement, 'Your input can only contain letters and numbers', from);
      this.toggleButtonState(true, from);
      return;
    }
    if (!query || query === existingSku) {
      query === existingSku && this.resetErrorMessages(['variantsTable'].includes(from) ? variantsErrorElement : errorElement, from);
      return;
    }

    this.timeout = setTimeout(() => {
      fetch(this.url('sku', query)).then(response => {
        response.text().then((res) => {
          const data = JSON.parse(res);
          if (!data['available']) {
            this.setElementError(['variantsTable'].includes(from) ? variantsErrorElement : errorElement, 'SKU is taken', from);
            this.toggleButtonState(['variantsTable'].includes(from)? this.variantsSkuBarcodeErrorCounter > 0 : true, from);
          } else {
            this.resetErrorMessages(['variantsTable'].includes(from) ? variantsErrorElement : errorElement, from);
          }
        });
      });
    }, 500);
  }

  validateBarcode(event) {
    clearTimeout(this.timeout);
    const { value: query, dataset: { existingBarcode, from, index } } = event.currentTarget;
    const [errorElement, variantsErrorElement] = [document.getElementById('product_barcode_error_message'), document.getElementById(`variant_${index}_barcode_error_message`)];
    if (query && !/^[a-zA-Z0-9]+$/.test(query)) {
      this.setElementError(['variantsTable'].includes(from) ? variantsErrorElement : errorElement, 'Your input can only contain letters and numbers', from);
      this.toggleButtonState(true, from);
      return;
    }
    if (!query || query === existingBarcode) {
      query === existingBarcode && this.resetErrorMessages(['variantsTable'].includes(from) ? variantsErrorElement : errorElement, from);
      return;
    }

    this.timeout = setTimeout(() => {
      fetch(this.url('barcode', query)).then(response => {
        response.text().then((res) => {
          const data = JSON.parse(res);
          if (!data['available']) {
            this.setElementError(['variantsTable'].includes(from) ? variantsErrorElement : errorElement, 'Barcode is taken', from);
            this.toggleButtonState(['variantsTable'].includes(from)? this.variantsSkuBarcodeErrorCounter > 0 : true, from);
          } else {
            this.resetErrorMessages(['variantsTable'].includes(from) ? variantsErrorElement : errorElement, from);
          }
        });
      });
    }, 500);
  }

  url(radix, query) {
    return ['sku'].includes(radix) ? `/admin/search/validate-sku?q=${query}` : `/admin/search/validate-barcode?q=${query}`;
  }

  toggleButtonState(flag, from) {
    if(['variantsTable'].includes(from)) {
      this.stepFourNextButton.disabled = flag;
    } 
    else {
      this.stepOneNextButton.disabled = flag;
    }
    this.productActionButton.disabled = flag;
  }

  setElementError(element, message, from) {
    ['variantsTable'].includes(from) && this.variantsSkuBarcodeErrorCounter++;
    element.innerHTML = message;
  }

  resetErrorMessages(element, from) {
    element.innerHTML = '';
    if (['variantsTable'].includes(from)) {
      this.variantsSkuBarcodeErrorCounter > 0 && this.variantsSkuBarcodeErrorCounter--;
      this.toggleButtonState(this.variantsSkuBarcodeErrorCounter > 0, from);
    } else {
      this.toggleButtonState(false, from);
    }
  }
}