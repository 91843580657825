import {Controller} from '@hotwired/stimulus';
import {popSnackbar} from '../services/snackbar';
import {freeFieldValidation} from '../services/authentications/free_field_service';
import {I18n} from '../translation';

export default class extends Controller {
  static targets = ['title', 'description', 'label', 'link', 'image', 'addButton', 'list'];

  tempData = {};
  actualData = [];
  currentLanguage = 'en';
  locales = ['en', 'de', 'fr'];
  mediaUrl = {};
  editing = false;
  buttonChecked = true;
  fallbackImageURL = '';
  acceptedFileTypes = ['image/jpeg', 'image/png', 'image/gif','video/mp4','video/avi','video/mov','video/webm'];
  formValidation = {
    validAdditionalLabel: {
      en: true,
      fr: true,
      de: true
    }
  };

  connect() {
    this.type = this.element.dataset.type;

    if (this.type === 'edit') {
      this.loadExistingData();
    }

    this.hasImageTarget && (this.fallbackImageURL = this.imageTarget.src);
    this.locales.forEach((locale) => {
      this.tempData[locale] = {
        title: '',
        description: '',
        label: '',
        link: '',
        medialUrl: {},
      };
    });
  }

  loadExistingData() {
    const existingData = JSON.parse(this.element.dataset.productInfo);

    this.actualData = existingData.filter(data => data.id !== null);
    this.updateList();
    this.previewContent();
  }

  switchLocale(event) {
    const selectedLanguage = event.target.dataset.locale;
    const scope = event.target.dataset.scope;
    const localeChips = document.querySelectorAll(`.locale-chips-${scope}`);

    if (this.currentLanguage === selectedLanguage) return;

    this.saveCurrentData();

    this.currentLanguage = selectedLanguage;

    localeChips.forEach((chip) => {
      if (chip.dataset.locale === selectedLanguage) {
        chip.classList.add('sk-language-active', 'active-locale');
        chip.classList.remove('sk-language-inactive');
      } else {
        chip.classList.remove('sk-language-active', 'active-locale');
        chip.classList.add('sk-language-inactive');
      }
    });
    this.updateForm();
    if (this.tempData && this.locales.includes(selectedLanguage)) {
      this.populateFieldsCreate(this.tempData, selectedLanguage);
    }
  }

  duplicateAll() {
    this.saveCurrentData();
    const sourceData = this.tempData[this.currentLanguage];
    if (!sourceData) {
      return;
    }
    const targetLocales = Object.keys(this.tempData).filter((locale) => locale !== this.currentLanguage);
    targetLocales.forEach((locale) => {
      this.tempData[locale] = {
        title: sourceData.title || '',
        description: sourceData.description || '',
        label: sourceData.label || '',
        link: sourceData.link || '',
        medialUrl: sourceData.medialUrl || {},
      };
    });
    this.validateProductInformation();
    popSnackbar('notice', 'Copied');
  }

  onFieldChange(event) {
    this.saveCurrentData(event);
    this.formValidation.validAdditionalLabel[this.currentLanguage] = freeFieldValidation(event.target, `${event.target.id}_error_message`);
    this.tempData[this.currentLanguage].title = event.target.value;
  }

  saveCurrentData() {
    const title = this.titleTarget.value;
    const description = this.descriptionTarget.value;
    const label = this.labelTarget.value;
    const link = this.linkTarget.value;

    this.tempData[this.currentLanguage] = {
      ...this.tempData[this.currentLanguage],
      title,
      description,
      label,
      link,
    };
  }

  updateForm() {
    const data = this.tempData[this.currentLanguage] || {};

    this.titleTarget.value = data.title || '';
    this.descriptionTarget.value = data.description || '';
    this.labelTarget.value = data.label || '';
    this.linkTarget.value = data.link || '';
    if (data.medialUrl) {
      this.hasImageTarget && (this.imageTarget.src = data.medialUrl.url);
    } else {
      const ImageElement = document.querySelector('.additional-info-image');
      const image = ImageElement.dataset.imageUrl;
      this.imageTarget.src = image;
    }
  }

  add(event) {
    event.preventDefault();
    if (!this.validateProductInformation()) {
      popSnackbar('error', 'Please ensure the content is entered or copied to all languages.');
      return;
    }

    if (this.editing) {
      this.saveEditedItem();
      const mediaUploadErrorElement = document.getElementById('media_upload_error_message');
      mediaUploadErrorElement && (mediaUploadErrorElement.classList.remove('hidden'));
      return;
    }
    this.actualData.push({
      title: {
        'en': this.tempData.en.title,
        'de': this.tempData.de.title,
        'fr': this.tempData.fr.title
      },
      description: {
        'en': this.tempData.en.description,
        'de': this.tempData.de.description,
        'fr': this.tempData.fr.description
      },
      button_metadata: {
        link: this.tempData.en.link,
        label: {
          'en': this.tempData.en.label,
          'de': this.tempData.de.label,
          'fr': this.tempData.fr.label
        }
      },
      media_urls: [...this.actualData.media_urls || [], {url: this.mediaUrl.url, type: this.mediaUrl.content_type, original_url: this.mediaUrl?.original_url}]
    });
    this.updateList();
    this.previewContent();
    this.clearForm();
    const previewContainer = document.getElementById('previewContainer');
    this.mediaUrl = {};
    document.getElementById('media_link').value = null;
    previewContainer.innerHTML = this.placeholderContainer;
  }


  updateList() {
    const data = this.actualData.filter(item => !item._destroy);
    const listContainer = this.listTarget;
    listContainer.innerHTML = '';

    Object.keys(data).forEach((key, index) => {
      const item = data[key];
      const listItem = document.createElement('div');
      listItem.className = 'relative border rounded-[4px] hover:border-blue cursor-pointer';

      const isVideo = item.media_urls?.[0]?.type?.startsWith('video/') ;

      listItem.innerHTML = `
      <div class="absolute inset-0 w-full p-[8px]">
        <div class="flex justify-between space-x-[16px]">
          <p class="text-[12px] font-CeraPro_Bold line-clamp-1">${item.title.en || ''}</p>
          <div>
            <i class="dropdown material-icons-outlined !text-[16px] p-[6px] rounded-full border hover:border-blue"
               type="button" id="dropdownMenuButton"
               data-bs-toggle="dropdown" aria-expanded="false">more_vert</i>
            <div class="dropdown-menu absolute hidden bg-white z-50 py-[8px] list-none rounded-lg shadow-lg border-none"
                 aria-labelledby="dropdownMenuButton">
              <button class="dropdown-item flex flex-col items-start text-black/[0.87] w-[188px] px-[16px] py-[8px] cursor-pointer hover:bg-blue/[0.08]"
                      type="button"
                      data-index="${index}" 
                      data-action="click->additional-info#editItem"
                      data-cy="editBtn">Edit</button>
              <button class="dropdown-item flex flex-col items-start text-black/[0.87] w-[188px] px-[16px] py-[8px] cursor-pointer hover:bg-blue/[0.08]"
                      type="button"
                      data-index="${index}" 
                      data-action="click->additional-info#deleteItem"
                      data-cy="deleteBtn">Delete</button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-center items-center pt-[12px]">
        ${isVideo ? `
          <iframe allowfullscreen src="${this.getEmbedUrl(item.media_urls?.[0]?.url) || ''}" type="${item.media_urls?.[0]?.type || 'video/mp4'}" controls width="100%" height="100%" class="object-contain max-h-[160px] p-[24px] mt-[24px]">
          </iframe>
        ` : `
          <img src="${item.media_urls?.[0]?.url || ''}" alt="Item Image" width="100%" height="100%" 
               class="object-contain max-h-[160px] p-[24px] mt-[24px]">
        `}
      </div>
    `;
      listContainer.appendChild(listItem);
    });
  }

  editItem(event) {
    const index = event.target.dataset.index;
    const item = this.actualData.filter(data => !data._destroy)[index];
    document.getElementById('editCancel').classList.remove('hidden');
    this.addButtonTarget.innerText = 'Update';
    document.getElementById('buttonInfo').innerText = 'Click the ‘Update’ button to save this section.';

    if (item) {
      this.rebuildFilePickerPreview(item);
      this.reGroupCurrentItemByLocale(item);
      this.populateFields(item, this.locales[0]);
      this.previewContent();
      this.currentItem = item; // Save the current item's data
      this.currentIndex = index;
      this.editing = true;
      document.getElementById('media_link').value = item.media_urls[0]?.original_url || item.media_urls[0]?.url;
    }
  }

  rebuildFilePickerPreview(item) {
    const previewContainer = document.getElementById('previewContainer');
    if (item.media_urls[0]?.type.includes('video/')) {
      previewContainer.classList.remove('h-[96px]');
      const embedUrl = this.getEmbedUrl(item.media_urls[0]?.url);
      previewContainer.innerHTML = '';
      const videoElement = document.createElement('iframe');
      videoElement.src = embedUrl;
      videoElement.controls = true;
      videoElement.classList.add('object-contain', 'w-full', 'h-[140px]');
      previewContainer.appendChild(videoElement);
    }
    else {
      previewContainer.classList.add('h-[96px]');
      previewContainer.innerHTML = this.placeholderContainer;
    }
    this.mediaUrl = {};
  }

  reGroupCurrentItemByLocale(item) {
    this.locales.forEach(locale => {
      this.tempData[locale].title = item.title[locale] || '';
      this.tempData[locale].description = item.description[locale] || '';
      this.tempData[locale].label = item.button_metadata.label[locale] || '';
      this.tempData[locale].link = item.button_metadata.link || '';
      this.tempData[locale].medialUrl = {url: item.media_urls[0]?.url || '', original_url: item.media_urls[0]?.original_url, content_type: item.media_urls[0]?.type };
    });
  }

  saveEditedItem() {
    if (this.type === 'edit') {
      this.mediaUrl = this.tempData.en.medialUrl;
    }
    const updatedItem = {
      id: this.currentItem.id,
      title: {
        'en': this.tempData.en.title,
        'de': this.tempData.de.title,
        'fr': this.tempData.fr.title
      },
      description: {
        'en': this.tempData.en.description,
        'de': this.tempData.de.description,
        'fr': this.tempData.fr.description
      },
      button_metadata: {
        link: this.tempData.en.link,
        label: {
          'en': this.tempData.en.label,
          'de': this.tempData.de.label,
          'fr': this.tempData.fr.label
        }
      },
      media_urls: [...this.actualData.media_urls || [], {
        url: this.mediaUrl.url,
        type: this.mediaUrl.content_type,
        original_url: this.mediaUrl?.original_url
      }]
    };

    this.actualData[this.currentIndex] = updatedItem;
    this.editing = false;
    this.updateList();
    this.addButtonTarget.innerText = 'Add';
    document.getElementById('editCancel').classList.add('hidden');
    document.getElementById('buttonInfo').innerText = 'Click the ‘Add’ button to save this section.';
    this.clearForm();
    const previewContainer = document.getElementById('previewContainer');
    previewContainer.innerHTML = this.placeholderContainer;
    this.mediaUrl = {};
    document.getElementById('media_link').value = null;
  }

  populateFieldsCreate(item, lang) {
    const ImageElement = document.querySelector('.additional-info-image');
    const image = ImageElement.dataset.imageUrl;
    this.titleTarget.value = item[lang].title || '';
    this.descriptionTarget.value = item[lang].description || '';
    this.labelTarget.value = item[lang].label || '';
    this.linkTarget.value = item[lang].link || '';
    this.hasImageTarget && (this.imageTarget.src = item[lang].image || image);
  }

  populateFields(item, lang) {
    this.titleTarget.value = item.title[lang] || '';
    this.descriptionTarget.value = item.description[lang] || '';
    this.labelTarget.value = item.button_metadata.label[lang] || '';
    this.linkTarget.value = item.button_metadata.link || '';
    this.hasImageTarget && (this.imageTarget.src = item.media_urls[0]?.url || '');
  }

  cancelEdit() {
    this.clearForm();
    this.addButtonTarget.innerText = 'Add';
    this.editing = false;
    document.getElementById('editCancel').classList.add('hidden');
    document.getElementById('buttonInfo').innerText = 'Click the ‘Add’ button to save this section.';
    document.getElementById('media_link').value = null;
    document.getElementById('previewContainer').innerHTML = this.placeholderContainer;
  }

  deleteItem(event) {
    const index = event.target.dataset.index;

    if (index !== undefined) {
      this.actualData[index]._destroy = 1;
      this.removeListItem(index);
      this.updateList();
      this.previewContent();
      this.clearForm();
    }
  }

  removeListItem(index) {
    const listContainer = this.listTarget;
    const listItem = listContainer.children[index]; // Find the corresponding DOM element

    if (listItem) {
      listContainer.removeChild(listItem);
    }
  }

  previewContent() {
    const data = this.actualData.filter(item => !item._destroy);
    const previewContainer = document.getElementById('additional-information-preview');

    if (previewContainer) {
      previewContainer.innerHTML = ''; // Clear existing preview

      Object.keys(data).forEach((key, index) => {
        const item = data[key];
        const previewElement = document.createElement('div');
        previewElement.className = 'grid-cols-1 gap-[24px] hidden lg:grid';

        const isVideo = item.media_urls?.[0]?.type?.startsWith('video/');

        previewElement.innerHTML = `
        <div class="flex ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'} items-center bg-gray-100 rounded-[4px]">
          <div class="flex-shrink-0 w-1/2 h-auto group relative overflow-hidden">
            ${isVideo ? `
              <iframe allowfullscreen controls src="${this.getEmbedUrl(item.media_urls?.[0]?.url) || ''}" type="${item.media_urls?.[0]?.type || 'video/mp4'}" width="100%" height="100%" class="rounded-[8px] mx-auto lg:mx-0 w-full object-contain h-[400px] transition-transform duration-300 ease-in-out transform group-hover:scale-110 hover:shadow">
              </iframe>
            ` : `
              <img src="${item.media_urls?.[0]?.url || ''}" alt="Item Image" width="100%" height="100%" 
                   class="rounded-[8px] mx-auto lg:mx-0 w-full object-contain h-[400px] transition-transform duration-300 ease-in-out transform group-hover:scale-110 hover:shadow">
            `}
          </div>
          <div class="w-1/2 ${index % 2 === 0 ? 'pr-[24px]' : 'pl-[24px]'} p-[24px]">
            <h3 class="text-[30px] font-CeraPro_Medium">${item.title.en || ''}</h3>
            <p class="mt-[8px] mb-[32px] line-clamp-5 cursor-pointer">${item.description.en || ''}</p>
            <a href="" target="_blank" class="${item.button_metadata.label.en ? 'block' : 'hidden'}">
              <button class="sk-btn sk-btn--secondary">
                ${item.button_metadata.label.en || ''}
              </button>
            </a>
          </div>
        </div>
      `;

        previewContainer.appendChild(previewElement);
      });
    }
  }

  clearForm() {
    const ImageElement = document.querySelector('.additional-info-image');
    const image = ImageElement.dataset.imageUrl;
    this.titleTarget.value = '';
    this.descriptionTarget.value = '';
    this.labelTarget.value = '';
    this.linkTarget.value = '';
    this.hasImageTarget && (this.imageTarget.src = image || '');

    Object.keys(this.tempData).forEach((locale) => {
      this.tempData[locale] = {
        ...this.tempData[locale],
        title: '',
        description: '',
        label: '',
        link: '',
        mediaUrl: {}
      };
    });
    const mediaErrorElement = document.getElementById('media_upload_error_message');
    mediaErrorElement && (mediaErrorElement.classList.add('hidden'));
  }

  validateProductInformation() {
    const titleField = document.getElementById('additional-info-title');
    const titleError = document.getElementById('additional-info-title_error_message');
    if (!titleField.value.trim()) {
      titleError.textContent = 'Title is required.';
      titleError.classList.remove('hidden');
    } else {
      titleError.textContent = '';
      titleError.classList.add('hidden');
    }

    const descriptionField = document.getElementById('additional_description');
    const descriptionError = document.getElementById('additional_description_error_message');
    if (!descriptionField.value.trim()) {
      descriptionError.textContent = 'Description is required.';
      descriptionError.classList.remove('hidden');
    } else {
      descriptionError.textContent = '';
      descriptionError.classList.add('hidden');
    }

    const imageError = document.getElementById('media_upload_error_message');
    if (!this.editing && imageError && !this.mediaUrl?.url) {
      imageError.textContent = 'A media file is required.';
      imageError.classList.remove('hidden');
    } else {
      imageError && (imageError.textContent = '');
      imageError && (imageError.classList.add('hidden'));
    }
    if (this.editing) {
      this.mediaUrl = this.tempData.en.medialUrl;
    }

    if (this.hasEnteredAButtonLabel){

      const linkErrorElement = document.getElementById('additional-info-link_error_message');
      if (!this.validateButtonInformation() && !this.hasEnteredAButtonLink) {
        linkErrorElement.style.display = 'block';
        linkErrorElement.textContent = 'A valid link is required';
        return false;
      }
      else {
        linkErrorElement.style.display = 'none';
      }
    }

    return Object.keys(this.tempData).every(locale =>
      ['title', 'description', 'medialUrl'].every(key => !!this.tempData[locale][key]) && (this.mediaUrl.url === this.tempData[locale].medialUrl.url)
    );
  }

  validateButtonInformation() {
    if (!this.buttonChecked) {
      return true;
    }
    return Object.keys(this.tempData).every(locale =>
      ['link', 'label'].every(key => !!this.tempData[locale][key])
    );
  }

  openFileDialog(event) {
    if (event.target.tagName !== 'INPUT') {
      const input = this.element.querySelector('input[type=\'file\']');
      if (input) {
        input.click();
      }
    }
  }

  async previewLink(event) {
    const url = event?.currentTarget?.value;
    if (!url) return;
    const mediaType = await this.detectMediaType(url);
    const embedUrl = this.getEmbedUrl(url);
    this.mediaUrl = {url: embedUrl, original_url: url, content_type: mediaType === 'video' ? 'video/mp4' : 'image/'};
    this.locales.forEach(locale => {
      this.tempData[locale].medialUrl = this.mediaUrl;
    });
    this.buildPreview(embedUrl, mediaType ===  'video');
  }

  uploadImageAdditionalInfo(event) {
    const file = event.target.files[0];
    if (!file) return;

    const maxImageSize = 2 * 1024 * 1024;
    const maxVideoSize = 150 * 1024 * 1024;

    const fileType = file.type.split('/')[0];

    if (fileType === 'image' && file.size > maxImageSize) {
      popSnackbar('error', I18n[window.currentLocale].file_too_large);
      return;
    }

    if (fileType === 'video' && file.size > maxVideoSize) {
      popSnackbar('error', I18n[window.currentLocale].file_too_large);
      return;
    }

    const previewContainer = document.getElementById('previewContainer');
    previewContainer.innerHTML = '';

    // **🔄 Show Loader**
    const loader = document.createElement('span');
    loader.classList.add('medial-upload-loader');
    loader.id = 'mediaUploadLoader'; // Add an ID for easy removal
    previewContainer.appendChild(loader);

    const progressText = document.createElement('p');
    progressText.classList.add('text-center', 'text-gray-500', 'text-sm', 'mt-2');
    progressText.innerText = 'Uploading...';
    previewContainer.appendChild(progressText);

    // **🚀 Start Upload with Progress**
    this.loadXHR(file, (progress) => {
      progressText.innerText = `Uploading... ${progress}%`;
    })
      .then((response) => {
        this.buildPreview(this.getEmbedUrl(response.url), file.type.startsWith('video/'));
        this.mediaUrl = response;
        document.getElementById('media_link').value = response.url;
        this.locales.forEach(locale => {
          this.tempData[locale].medialUrl = {...this.mediaUrl, original_url: response.url };
        });
      })
      .catch((error) => {
        console.error('Upload error:', error);
        popSnackbar('error', 'Upload failed.');
      });

    event.target.value = ''; // Reset input
  }

  buildPreview(url, isVideo) {
    const previewContainer = document.getElementById('previewContainer');
    previewContainer.innerHTML = '';

    // **📽️ Show Video Preview**
    if (isVideo) {
      const videoElement = document.createElement('iframe');
      videoElement.src = url;
      videoElement.controls = true;
      videoElement.classList.add('object-cover', 'w-fit', 'h-[140px]');
      previewContainer.appendChild(videoElement);
    }
    // **🖼️ Show Image Preview**
    else {
      const imgElement = document.createElement('img');
      imgElement.src = url;
      imgElement.classList.add('object-contain', 'w-full', 'h-[100px]');
      previewContainer.appendChild(imgElement);
    }
  }

  detectMediaType(url) {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve('image');
      img.onerror = () => resolve('video');
    });
  }

  getEmbedUrl(url) {
    const youtubeRegex = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/;
    const vimeoRegex = /vimeo\.com\/(?:video\/)?(\d+)/;

    let match;

    if ((match = url.match(youtubeRegex))) {
      return `https://www.youtube.com/embed/${match[1]}`;
    } else if ((match = url.match(vimeoRegex))) {
      return `https://player.vimeo.com/video/${match[1]}`;
    }

    return url;
  }

  loadXHR(file, onProgress) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', '/upload', true);
      xhr.responseType = 'json'; // Expecting JSON response

      // **Track Upload Progress**
      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            onProgress(100); // Ensure it shows complete only when truly done
            resolve(xhr.response);
          } else {
            reject('Upload error: ' + xhr.statusText);
          }
        }
      };

      xhr.onerror = () => reject('Network error.');
      xhr.onload = function () {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject('Upload error: ' + xhr.statusText);
        }
      };

      const formData = new FormData();
      formData.append('photo', file); // Make sure the param name matches in Rails
      xhr.send(formData);
    });
  }

  unPreventFormSubmit(e) {
    if (e.keyCode === 13) {
      e.stopImmediatePropagation();
    }
  }

  enableToggleBtn(event) {
    this.buttonChecked = event.target.checked;
    if (!this.buttonChecked) {
      Object.keys(this.tempData).forEach((locale) => {
        this.tempData[locale].label = '';
        this.tempData[locale].link = '';
      });
    }
    document.getElementById('additional_info_btn').style.display = event.target.checked ? 'flex' : 'none';
  }

  get placeholderContainer() {
    return `<img id="additional-info-image" src="${this.fallbackImageURL}" alt="Media upload" width="100%" height="100%" data-additional-info-target="image">`;
  }

  get hasEnteredAButtonLabel() {
    return Object.keys(this.tempData).some(locale =>
      ['label'].some(key => !!this.tempData[locale][key])
    );
  }

  get hasEnteredAButtonLink() {
    return Object.keys(this.tempData).some(locale =>
      ['link'].some(key => !!this.tempData[locale][key])
    );
  }

}
