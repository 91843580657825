import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';

export default class extends Controller {

  productSeo = {
    title: '',
    description: '',
    url: '',
    brand: '',
    offer_price: '',
    offer_currency: '',
    validity: '',
    keywords: [],
    image_url: ''
  };

  productSeoForm = [
    {
      id: 'title',
      error: 'title-error',
      required: true
    },
    {
      id: 'description',
      error: 'description-error',
      required: true
    },
    {
      id: 'url',
      error: 'url-error',
      required: true
    },
    {
      id: 'brand',
      required: false
    },
    {
      id: 'offer_price',
      required: false
    },
    {
      id: 'offer_currency',
      required: false
    },
    {
      id: 'validity',
      required: false
    },
    {
      id: 'image_url',
      required: false
    }
  ];

  uniqKeywords = [];
  connect() {
    if(this.element.dataset.metadata){
      this.productSeo = {...JSON.parse(this.element.dataset.metadata)};
      this.uniqKeywords = this.productSeo.keywords;
      this.productSeo.image_url = this.productSeo.image_urls[0];
      document.getElementById('validity').value =
          this.productSeo.validity && this.productSeo.validity.substring(0, 10) || '';
      this.addEventListener();
      this.addEnterKeyWords();
      this.previewDisplay();
      this.displayChips();
      this.enableButton();
    }
  }

  addEventListener() {
    this.productSeoForm.forEach( value => {
      this.addRequiredValidation(value.id, value.error, value.required);
    });
  }

  addRequiredValidation(id, showErrorId, validation) {
    let element;
    if(id === 'brand'){
      element = document.getElementById('p-brand');
    } else {
      element = document.getElementById(id);
    }

    element.addEventListener('input', (e) => {
      if(id !== 'url'){
        this.productSeo[id] = element.value;
      } else {
        if (e.data === ' ' || !/[a-zA-Z0-9]/.test(e.data)){
          const inputValue = e.target.value;
          // Remove spaces and special characters
          e.target.value = inputValue.replace(/[^a-zA-Z0-9]/g, '');
          document.getElementById('url-type-error').style.display = 'block';
        } else {
          this.productSeo['url'] = element.value;
          document.getElementById('url-type-error').style.display = 'none';
        }
      }
      this.enableButton();
      if(id === 'image_url'){
        this.previewImage();
      }
      this.previewDisplay();
      if (showErrorId && element.value.length > 0) {
        document.getElementById(showErrorId).style.display = 'none';
      }
    });
    if (validation) {
      element.addEventListener('blur', () => {
        if (element.value.length <= 0) {
          document.getElementById(showErrorId).style.display = 'block';
        }
      });
    }
  }

  addEnterKeyWords() {
    const element = document.getElementById('keywords');
    element.addEventListener('input', () => {
      document.getElementById('keywords-error').style.display = 'none';
      element.addEventListener('keydown', (event) => {
        if(event.code === 'Enter' && !!element.value.length){
          if(this.uniqKeywords.includes(element.value)){
            document.getElementById('keywords-error').style.display = 'block';
          }
          document.getElementById('keywords-required-error').style.display = 'none';
          this.uniqKeywords.push(element.value.trim());
          this.uniqKeywords = this.uniqKeywords.filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
          this.displayChips();
          this.enableButton();
          element.value = '';
        }
      });
    });
  }

  removeChips(event) {
    const value = event.currentTarget.parentElement.querySelector('#keyword-value').innerText;
    this.uniqKeywords.splice(this.uniqKeywords.indexOf(value), 1);
    if(!this.uniqKeywords.length){
      document.getElementById('keywords-required-error').style.display = 'block';
    }
    this.displayChips();
    this.enableButton();
  }

  displayChips() {
    document.getElementById('keyword-chips').innerHTML = '';
    for(let i= 0; i < this.uniqKeywords.length; i++) {
      document.getElementById('keyword-chips').innerHTML += `
            <div class="flex items-center space-x-[8px] sk-chip-inactive rounded-full cursor-pointer px-[12px] py-[6px] mr-[16px] mt-[16px]" data-mdb-ripple="true">
            <p id="keyword-value">${this.uniqKeywords[i]}</p>
            <i class="material-icons-outlined !text-[18px] text-blue" data-action="click->edit-product-seo#removeChips">close</i>
          </div>
            `;
    }
  }

  enableButton() {
    const enable = ['title', 'url', 'description'].every( value => !!this.productSeo[value]);
    document.getElementById('optimised-button').disabled = !enable || !this.uniqKeywords.length;
  }

  previewDisplay() {
    document.getElementById('preview-title').innerText = this.productSeo.title;
    document.getElementById('preview-price').innerText = `${this.productSeo.offer_currency} ${this.productSeo.offer_price}`;
    document.getElementById('preview-brand').innerText = 'Brand: ' + this.productSeo.brand;
    document.getElementById('find-more').innerText = 'Find more '+ this.productSeo.title +' at Swiss Ski';
  }

  previewImage() {
    if(this.productSeo.image_url){
      document.getElementById('preview-image').src = this.productSeo.image_url;
    }
  }

  submit() {
    let offerPrice = parseFloat(this.productSeo.offer_price); // Convert to a number if it's a string
    let formattedPrice = offerPrice.toFixed(2);
    const payload = {
      ...this.productSeo,
      image_urls: this.productSeo.image_url && [this.productSeo.image_url] || [],
      keywords: this.uniqKeywords,
      offer_price: formattedPrice
    };
    delete payload?.url;
    let url = ` /admin/search_engine_values/${this.productSeo.id}`;
    const _this = this;
    fetch( url, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    }).then((response) => response.json())
      .then((data) => {
        if (data.done) {
          popSnackbar('notice', data.message);
          _this.cancel();
        } else {
          popSnackbar('error', data.message);
        }
      });
  }

  cancel() {
    window.location.href = '/admin/product_configurator';
  }
}
