export const I18nDE = {
  confirm_member: 'Bitte überprüfe, ob du bereits offizielles Mitglied bist.',
  uncheck_member:
        'Bitte entferne das Häkchen, falls du kein offizielles Mitglied bist.',
  password_requirement:
        'Notwendige Zeichen: Grossbuchstaben, Kleinbuchstaben, Zahl, Sonderzeichen. Mindestens 8 Zeichen.',
  name: 'Name: ',
  phone: 'Phone: ',
  state: 'State: ',
  country: 'Country: ',
  apartment: 'Hausnummer: ',
  zipcode: 'Postleitzahl: ',
  permission_changed: 'Permission update successful.',
  something_went_wrong: 'Etwas ist schief gelaufen.',
  required_field: 'Please fill up the input fields.',
  role_update_success: 'New role assigned.',
  role_update_failure: 'Error assigning role.',
  snack_msg_on_user_deletion: 'Selected user(s) successfully deleted.',
  snack_msg_on_user_removal:
        'Selected user(s) successfully removed from the current role.',
  snack_msg_on_subcategory_removal:
        'Selected subcategory(s) successfully removed from the current category.',
  admin_invitation: 'User invite successfully sent.',
  admin_invitation_failure: 'Error sending invite.',
  people: 'People',
  cms: 'Web CMS',
  retailers: 'Retailers',
  successfully_subscribed: 'Erfolgreich abonniert',
  product_configurator: 'Product Configurator',
  inventory_management: 'Inventory Management',
  order_management: 'Order Management',
  gift_card_detail: 'Gift Card',
  seo_management: 'SEO Management',
  product_tags: 'Product Tags',
  products: 'Produkte',
  reports: 'Reports',
  promotion: 'Promotion',
  remove_subcategory: 'Remove Subcategory',
  sub_category: 'Subcategory name',
  product_type_creation_notice: 'Product type successfully created.',
  category_creation_notice: 'Category successfully created.',
  sub_category_creation_notice: 'Subcategory successfully created.',
  content_duplication_success_notice: 'Copied',
  content_duplication_success_error: 'Error Copying.',
  add_option_value: 'Add value',
  selected: 'Selected',
  variant: 'Variant',
  stock: 'Stock',
  continue_as_guest: 'Als Gast fortfahren',
  its_my_first_time: 'Ich bin zum ersten Mal am Start',
  continue_with_registration: 'Weiter mit Registrierung',
  initial_stock_quantity: 'Initial Stock Quantity',
  product_type_edition_notice: 'Product type successfully updated.',
  category_edition_notice: 'Category successfully updated.',
  subcategory_edition_notice: 'Subcategory successfully updated.',
  variant_creation_success_notice: 'Variant successfully created.',
  variant_updation_success_notice: 'Variant successfully updated.',
  order_state_updated_successful: 'Order state updated successfully',
  order_state_updated_unsuccessful: 'Order state updated unsuccessful',
  active_product: 'Product visibility on',
  inactive_product: 'Product visibility off',
  product_added_cart: 'Produkt zum Warenkorb hinzugefügt.',
  field_required: 'Pflichtfeld',
  invalid_email: 'Ungültige E-Mail-Adresse',
  password_should_match: 'Passwort muss übereinstimmen.',
  invalid_member_id: 'Ungültige Mitglieder-ID',
  invalid_phone_example: 'Ungültige Telefonnummer - Beispiel:',
  already_in_cart:
        'Das ausgewählte Produkt befindet sich bereits im Warenkorb, bitte erhöhe die Menge im Warenkorb.',
  limit_stock_reached: 'Limit Stock Reached for the Product.',
  minimum_order_quantity:
        'Cannot order item quantity less than 1, please remove the item by pressing the cross button',
  brands: 'Brands',
  color: 'Color',
  size: 'Size',
  first_name: 'Vorname: ',
  last_name: 'Nachname: ',
  street_name: 'Strasse: ',
  city: 'Ort: ',
  change: 'Ändern',
  invalid_zipcode: 'Ungültige PLZ',
  successful_copy: 'Successfully Copied.',
  discount_deleted: 'Discount Deleted Successfully',
  invalid_value: 'Invalid Value',
  invalid_timing: 'Invalid Timing',
  promo_applied_successfully: 'Promo-Code erfolgreich angewendet',
  gift_card_applied_successfully: 'Gift card applied successfully',
  gift_card_removed_successfully: 'Gift card removed successfully',
  for_specific_product: 'For Specific Product',
  for_specific_collection: 'For Specific Collection',
  for_all_product: 'For All Product',
  invalid_street: 'Ungültige Adresse.- Beispiel: Altweg 47',
  internal_categories: 'Internal Categories',
  categories: 'Categories',
  email_exist: 'Email existiert bereits',
  please_login: 'Bitte einloggen',
  payment: 'Zahlung',
  shipping: 'Versand',
  No_gift_product_selected: 'Kein Geschenkprodukt ausgewählt',
  for_membership: 'For Membership',
  snowactive: 'Gratis Snowactive Magazin (4x jährlich per Post) / Newsletter',
  snow_active_newsletter: 'Newsletter',
  fill_required_fields:
        'Bitte füllen Sie das/die erforderliche(n) Feld(er) aus',
  stay_up_to_date: 'Stay up to date about the swiss-ski teams',
  subscribe_now: 'Subscribe Now',
  for_you: 'For you',
  members_benefit: 'Members Benefit',
  place_order: 'Bestellen',
  proceed_to_payment: 'Weiter zur kasse',
  retailer_selected: 'Fachhändler bereits ausgewählt',
  retailer_success: 'Fachhändler erfolgreich ausgewählt.',
  retailer_unsuccessful: 'Fachhändler erfolgreich ausgewählt.',
  sub_categories: 'Sub Categories',
  coupon_invalid: 'Ein oder mehrere Gutscheine haben das Nutzungslimit erreicht.'
};
